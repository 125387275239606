import React, { useState, useRef } from "react";
import { format } from "date-fns";
import { debounce } from "lodash";

import "./App.css";
import fetchAlbum from "./fetchAlbum";

var Airtable = require("airtable");
var base = new Airtable({ apiKey: "keyi9N24pFDMwr9YS" }).base(
  "apptj7PUFe5d76gRO"
);
const airtableLink = (name) =>
  name === "Frankie"
    ? "https://airtable.com/tbli9tqrwAg8lCwcz/viwxjQ6JI0ObIn8f1"
    : "https://airtable.com/tbli9tqrwAg8lCwcz/viwCxyk0OknXZW8qM";
const otherName = (name) => (name === "Frankie" ? "Brittany" : "Frankie");
const regex = /album\/(.+)\?.+/;

const submitToAirtable = (name, linkVal, spotifyToken) => {
  const match = linkVal.match(regex);
  if (!match) {
    throw new Error("no match");
  }
  const albumId = match[1];
  fetchAlbum(albumId, spotifyToken).then((album) => {
    if (album) {
      base("Table 1").create(
        [
          {
            fields: {
              Title: album.name,
              Genre: album.genres.join(", "),
              Artist: album.artists.map((artist) => artist.name).join(", "),
              Recommendee: otherName(name),
              "Spotify link": linkVal,
              "Spotify ID": albumId,
              "Release Date": format(new Date(album.release_date), "M/d/yyyy"),
              "Recommend Date": format(new Date(), "M/d/yyyy"),
              "Listened?": false,
              "Number of Tracks": album.total_tracks,
              "Spotify Popularity": album.popularity,
            },
          },
        ],
        function (err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
    }
  });
};

const updateReviewInAirtable = (review, id) => {
  base("Table 1").update(
    [
      {
        id,
        fields: {
          Review: review,
        },
      },
    ],
    function (err) {
      if (err) {
        console.error(err);
        return;
      }
    }
  );
};

function RecPage({ name }) {
  const [album, setAlbum] = useState(null);
  const [fetchedAirtable, setFetchedAirtable] = useState(false);
  const [spotifyToken, setSpotifyToken] = useState(false);
  const [linkVal, setLinkVal] = useState("");
  const [reviewValue, setReviewValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  // const [fetchedSpotify, fetchedSpotify] = useState(false);
  if (!fetchedAirtable) {
    base("Table 1")
      .select({
        view: name,
      })
      .firstPage(function (err, records) {
        setFetchedAirtable(true);
        if (err) {
          console.error(err);
          return;
        }
        setReviewValue(records[records.length - 1].fields.Review || "");
        setAlbum(records[records.length - 1]);
      });
  }

  if (!spotifyToken) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Basic ${process.env.REACT_APP_SPOTIFY_PASSWORD}`
    );
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://accounts.spotify.com/api/token", requestOptions)
      .then((response) => response.text())
      .then((json) => {
        const result = JSON.parse(json);
        setSpotifyToken(result.access_token);
      })
      .catch((error) => console.log("error", error));
  }

  const updateReview = (reviewValue, albumId) => {
    updateReviewInAirtable(reviewValue, albumId);
    setReviewSubmitted(true);
  };
  const debouncedReviewUpdate = useRef(debounce(updateReview, 1500)).current;

  return (
    <div className="RecPage">
      <h2>
        Hi [{name}]!
        <br></br> Your latest album rec from [{otherName(name)}] is:
      </h2>
      <div>
        {album ? (
          <div>
            <h3 style={{ backgroundColor: "#21D19F", display: "inline-block" }}>
              <b>{album.fields.Title}</b>
            </h3>
            <br></br>
            <h3
              style={{
                backgroundColor: "#e868ff",
                display: "inline-block",
              }}
            >
              <b>by {album.fields.Artist}</b>
            </h3>
            <h4>
              <i>
                Recommended on{" "}
                {format(new Date(album.fields["Recommend Date"]), "M/d/yyyy")}
              </i>
            </h4>
            <iframe
              src={`https://open.spotify.com/embed/album/${album.fields["Spotify ID"]}`}
              width="300"
              height="300"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              title="Spotify"
            ></iframe>
            <br></br>
            <br></br>{" "}
            <label style={{ fontWeight: 700, textAlign: "left" }}>
              Your Review ({reviewValue.length}/280): <br></br>
              <textarea
                style={{
                  maxWidth: "350px",
                  width: "90%",
                  height: "150px",
                  border: "2px solid #7293A0",
                  fontSize: "16px",
                  padding: "5px",
                }}
                value={reviewValue}
                maxLength="280"
                onChange={(e) => {
                  setReviewValue(e.target.value);
                  setReviewSubmitted(false);

                  debouncedReviewUpdate(e.target.value, album.id);
                }}
              >
                {reviewValue}
              </textarea>
            </label>
            <br></br>
            {reviewSubmitted && <span>Review saved!</span>}
          </div>
        ) : (
          <p>
            {fetchedAirtable
              ? `[${otherName(name)}] hasn't recommended you anything yet! Watch
            this space.`
              : "Loading..."}
          </p>
        )}
      </div>
      <h2>Recommend an album to [{otherName(name)}]:</h2>
      <label style={{ fontWeight: 700 }}>
        Spotify Link:{" "}
        <input
          style={{ width: "150px", border: "2px solid #7293A0" }}
          value={linkVal}
          onChange={(e) => {
            setLinkVal(e.target.value);
            if (submitted) {
              setSubmitted(false);
            }
          }}
        ></input>
      </label>{" "}
      <br></br>
      <br></br>
      <button
        disabled={!linkVal}
        style={{
          background: linkVal ? "#7293A0" : "#A0A4B8",
          border: "4px solid #A0A4B8",
          padding: "5px",
          textTransform: "uppercase",
          fontWeight: 700,
          fontSize: "18px",
          cursor: linkVal ? "pointer" : "initial",
        }}
        onClick={() => {
          submitToAirtable(name, linkVal, spotifyToken);
          setSubmitted(true);
          setLinkVal("");
        }}
      >
        Submit Rec
      </button>
      {submitted && (
        <span>
          <br></br>Submitted!
        </span>
      )}
      <br></br>
      <br></br>
      <a href={airtableLink(name)}>See what [Frankie]'s recommended</a>
      <br></br>
      <a href={airtableLink(otherName(name))}>See what you've recommended</a>
    </div>
  );
}

export default RecPage;
