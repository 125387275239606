import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import RecPage from "./RecPage";
import Login from "./Login";

function App() {
  return (
    <Router>
      <div className="App">
        <h1
          style={{
            textAlign: "left",
            padding: "5px",
            border: "4px solid #A0A4B8",
          }}
        >
          Record~~~~~~<br></br>
          <span style={{ marginLeft: "20px" }}>~mendations</span>
        </h1>

        <Switch>
          <Route path="/brittany">
            <RecPage name="Brittany" />
          </Route>
          <Route path="/frankie">
            <RecPage name="Frankie" />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
