import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="Login">
      <h2>I am:</h2>
      <Link to="/frankie">Frankie</Link>
      <br></br>
      <Link to="/brittany">Brittany</Link>
    </div>
  );
}

export default Page;
