const fetchAlbum = (albumId, spotifyToken) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${spotifyToken}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "client_credentials");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`https://api.spotify.com/v1/albums/${albumId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => console.log("error", error));
};

export default fetchAlbum;
